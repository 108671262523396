<template>
  <div>
    <c-table
      ref="table"
      :title="'\'' + popupParam.requestContents + '\'에 대한 즉시조치 목록'"
      tableId="imprRequest"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      selection="multiple"
      rowKey="sopImprovementId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="Excel 업로드" icon="add" @btnClicked="openExcelUploader" />
          <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addImpr" />
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="POST"
            label="개선요청 저장" 
            icon="save"
            @beforeAction="saveImprRequest"
            @btnCallback="saveCallback" />
          <!-- <c-btn v-if="editable" label="개선요청 추가" icon="save" @btnClicked="addImprRequest" /> -->
          <c-btn v-if="editable" label="제외" icon="remove" @btnClicked="removeImpr" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'impr-immediate',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          requestContents: '',
          ibmTaskTypeCd: '',
          plantCd: '',
          processCd: '',
          sopMapId: '',
          approvalUserId: '',
          ibmTitle: '',
          row: {
            riskOccurrenceCause: '',
            riskHazardClassCd: '',
            sopName: '',
            processName: '',
          }
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
        height: '500px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.ibm.improve.list.url
      this.saveUrl = transactionConfig.sop.ibm.improve.insert.url
      // code 
      this.$comm.uploderSetting('IBM_BEFORE').then(_result => {
        this.$comm.uploderSetting('IBM_AFTER').then(_result2 => {
          this.grid.columns = [
            {
              name: 'impr',
              field: 'impr',
              label: '개선담당',
              align: 'center',
              child: [
                {
                  name: 'actionDeptName',
                  field: 'actionDeptName',
                  label: '조치업체',
                  align: 'center',
                  style: 'width:120px',
                  sortable: false,
                  required: true,
                },
                {
                  name: 'actionUserName',
                  field: 'actionUserName',
                  label: '조치담당자',
                  userId: 'actionUserId',
                  type: 'user',
                  align: 'center',
                  style: 'width:120px',
                  sortable: false,
                  required: true,
                },
              ]
            },
            {
              name: 'ibmTitle',
              field: 'ibmTitle',
              label: '제목',
              align: 'left',
              style: 'width:280px',
              sortable: false,
              type: 'text',
              required: true,
            },
            {
              name: 'actionContents',
              field: 'actionContents',
              label: '조치내용',
              align: 'left',
              style: 'width:400px',
              sortable: false,
              type: 'textarea',
              required: true,
            },
            {
              name: 'actionCompleteDate',
              field: 'actionCompleteDate',
              label: '조치완료일',
              align: 'center',
              style: 'width:120px',
              sortable: false,
              type: 'date',
              required: true,
            },
            {
              name: 'before',
              field: 'before',
              label: '개선 전 사진',
              align: 'center',
              style: 'width:200px',
              type: 'attach',
              taskClassCd: 'IBM_BEFORE',
              keyText: 'sopImprovementId',
              sortable: false,
              uploaderSetting: _result,
            },
            {
              name: 'after',
              field: 'after',
              label: '개선 후 사진',
              align: 'center',
              style: 'width:200px',
              type: 'attach',
              taskClassCd: 'IBM_AFTER',
              keyText: 'sopImprovementId',
              sortable: false,
              uploaderSetting: _result2,
            },
          ];
        })
      })
      // list setting
    },
    /* eslint-disable no-unused-vars */
    openExcelUploader() {
      this.popupOptions.title = '즉시개선 엑셀 업로드'; // 즉시개선
      this.popupOptions.param = this.popupParam;
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmediateExcelUpload.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          item.relationTableKey = this.popupParam.relationTableKey;
          item.plantCd = this.popupParam.plantCd ? this.popupParam.plantCd : this.$store.getters.user.plantCd
          item.ibmTaskTypeCd = this.popupParam.ibmTaskTypeCd;
          item.ibmStepCd = 'IS00000020';
          item.ibmClassCd = 'IC00000005';
          item.improveRequestDeptCd = this.$store.getters.user.selfVendorCd;
          item.improveRequestDeptName = this.$store.getters.user.selfVendorName;
          item.improveRequestUserId = this.$store.getters.user.userId;
          item.improveRequestUserName = this.$store.getters.user.userName;
          item.ibmTitle =  this.popupParam.ibmTitle,
          // 공종, 작업, 위치정보 자동표시
          item.processName = this.popupParam.row.processName;
          item.sopName = this.popupParam.row.sopName;
          item.maps = this.popupParam.row.maps;
          item.companyCd = this.$store.getters.user.companyCd,
          // 점검자
          item.regUserId = this.popupParam.row.assessUserId;
          // 승인자
          item.approveUserId = this.popupParam.approvalUserId;
          item.companyCd= this.$store.getters.user.companyCd
          item.regDt = this.$comm.getToday();
          item.editFlag = 'C';
        })
        this.grid.data = this.$_.concat(this.grid.data, s_data)
      }
    },
    addImpr() {
      this.popupOptions.title = '조치업체';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.popupParam.plantCd ? this.popupParam.plantCd : this.$store.getters.user.plantCd,
        processCd: this.popupParam.processCd,
      }
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          // if (this.$_.findIndex(this.grid.data, { actionDeptCd: item.deptCd }) === -1) {
          this.grid.data.push({
            relationTableKey: this.popupParam.relationTableKey,
            plantCd: this.popupParam.plantCd ? this.popupParam.plantCd : this.$store.getters.user.plantCd,
            ibmTaskTypeCd: this.popupParam.ibmTaskTypeCd,
            ibmStepCd: 'IS00000020',
            ibmClassCd: 'IC00000005',
            improveRequestDeptCd: this.$store.getters.user.selfVendorCd,
            improveRequestDeptName: this.$store.getters.user.selfVendorName,
            improveRequestUserId: this.$store.getters.user.userId,
            improveRequestUserName: this.$store.getters.user.userName,
            actionDeptCd: item.vendorCd,
            actionDeptName: item.vendorName,
            vendorCd: item.vendorCd,
            companyFlag: item.companyFlag,
              // 공종, 작업, 위치정보 자동표시
            processName: this.popupParam.row.processName,
            sopName: this.popupParam.row.sopName,
            maps: this.popupParam.row.maps,
            improveRequestContents: '',
            actionCompleteRequestDate: this.$comm.getToday(),
            sopImprovementId: uid(),
            ibmTitle: this.popupParam.ibmTitle,
            companyCd: this.$store.getters.user.companyCd,
              // 점검자
            regUserId: this.popupParam.row.assessUserId,
              // 승인자
            regDt: this.$comm.getToday(),
            editFlag: 'C',
          })
        })
      }
    },
    saveImprRequest() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인', 
          message: '목록에 표시된 즉시조치를 \'' + this.popupParam.requestContents + '\'항목에 대한 즉시개선건으로 추가합니다.\n\r 저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback(result) {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        relationTableKey: this.popupParam.relationTableKey,
        ibmTaskTypeCd: this.popupParam.ibmTaskTypeCd,
      }
      this.$http.request((_result) => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.$emit('closePopup', _result.data);
      },);
    },
    removeImpr() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid.data.splice(this.$_.findIndex(this.grid.data, { sopImprovementId: item.sopImprovementId }), 1)
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
        
      }
    }
  }
};
</script>
